.label {
  margin-bottom: 4px;
  margin-top: 10px;
  flex: 1;
}
.input {
  padding: 12px;
  width: 30px;
  height: 30px;
  margin: 5px 0 10px 3px;
  flex: 0.5;
}
.gap {
  justify-content: space-between;
}
.row {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}
.none {
  display: block;
}
p span {
  font-size: 18px;
  color: #d76363;
}
.moreInfo {
  font-size: 0.89em;
  color: #555;
}
