.root {
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;
  cursor: pointer;
}
.text,
ul.list li {
  font-size: 0.8rem;
}
.modal {
  position: absolute;
  width: 30px;
  top: 210%;
  left: 0;
  color: black;
  padding: 5px 15px 15px 15px;
  z-index: 100;
  border-radius: 0 0 3px 3px;
}
ul.list li {
  margin-top: 10px;
}

ul.list li:hover {
  color: chocolate;
}

/* New media query */
@media only screen and (min-width: 375px) and (max-width: 412px) {
  .modal {
    top: 190%;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .modal {
    top: 190%;
  }
}
/* Media Queries iphone 6-8 uses this */
@media only screen and (max-width: 767px) {
  .modal {
    top: 190%;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 812px) {
}
@media only screen and (min-width: 813px) and (max-width: 1024px) {
}
/* End of new media query */
