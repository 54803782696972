* {
  margin: 0;
  padding: 0;
}

/* @import url("https://fonts.googleapis.com/css2?family=Alata&family=Lato&family=Merriweather+Sans:wght@500&display=swap"); */

html body {
  font-size: 16px;
  font-family: Lato;
  height: 100%;
  overflow: hidden;
  cursor: default;
  user-select: none;
  line-height: 1.5em;
}
/* * ::-webkit-scrollbar {
  display: none;
} */

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Example styles for Firefox scrollbar */
* {
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Alata;
  margin-bottom: 10px;
}

h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2.4em;
}
h3 {
  font-size: 1.4em;
}
h4 {
  font-size: 1.2em;
}

:root {
  /* --primary-color: ;
  --primary-light: ; */
  /* --secondary-color: #323db8; */
  --secondary-color: #007bff;
  --secondary-color600: #435dcb;
  --secondary-color500: #007bffd3;
  --secondary-color200: #7981d8;
  --secondary-color100: #2a85e6;
}
