.root {
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;
  cursor: pointer;
}

ul.list li {
  font-size: 1rem;
}
.modal {
  position: absolute;
  top: 210%;
  left: 0;
  width: 300px;
  color: black;
  transform: translate(-93%);
  padding: 5px 15px 15px 15px;
  z-index: 100;
  border-radius: 0 0 3px 3px;
}
ul.list li {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #aaa;
  padding-bottom: 20px;
}
ul.list li:last-child {
  border-bottom: none;
}

ul.list li:hover {
  color: chocolate;
}
.counter {
  position: absolute;
  top: -6px;
  right: 3px;
  background-color: rgb(223, 44, 44);
  padding: 3px 6px;
  font-size: 9px;
  border-radius: 100px;
  transform: translateX(60%); /* Adjust the percentage as needed */
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.08em;
  line-height: 1.5em;
}

/* New media query */
/* Mobile view */
@media only screen and (min-width: 375px) and (max-width: 412px) {
  .modal {
    top: 190%;
    transform: translate(-63%);
  }
  ul.list li {
    font-size: 0.9rem;
  }
}

/* landscape mobile view */
@media only screen and (max-width: 812px) and (orientation: landscape) {
  .modal {
    top: 190%;
    transform: translate(-93%) !important;
  }
}
/* Media Queries iphone 6-8 uses this */
@media only screen and (max-width: 767px) {
  .modal {
    top: 190%;
    transform: translate(-63%);
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 812px) {
}
@media only screen and (min-width: 813px) and (max-width: 1024px) {
}
/* End of new media query */
