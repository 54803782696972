.root {
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;
  cursor: pointer;
}

ul.list li {
  font-size: 1rem;
}
.modal {
  position: absolute;
  top: 210%;
  left: -300%;
  width: 200px;
  color: black;
  /* transform: translate(-10%); */
  padding: 5px 20px 20px 20px;
  z-index: 100;
  border-radius: 0 0 3px 3px;
}
ul.list li {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}

ul.list li:hover {
  color: chocolate;
}
.counter {
  position: absolute;
  top: -6px;
  right: 3px;
  background-color: rgb(223, 44, 44);
  padding: 3px 6px;
  font-size: 9px;
  border-radius: 100px;
  transform: translateX(60%); /* Adjust the percentage as needed */
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.08em;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
/* New media query */
/* Mobile view */
@media only screen and (min-width: 375px) and (max-width: 412px) {
  .modal {
    top: 190%;
    /* transform: translate(-70%) !important; */
  }
  .text {
    max-width: 50px !important;
  }
  ul.list li {
    font-size: 0.9rem;
  }
}

/* landscape mobile view */
@media only screen and (max-width: 812px) and (orientation: landscape) {
  .modal {
    top: 190%;
    /* transform: translate(-50%); */
  }
}
/* Media Queries iphone 6-8 uses this */
@media only screen and (max-width: 767px) {
  .modal {
    top: 190%;
    /* transform: translate(-50%); */
  }
  .text {
    max-width: 100px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 812px) {
  .modal {
    /* transform: translate(-8%); */
  }
}
@media only screen and (min-width: 813px) and (max-width: 1024px) {
  .modal {
    /* transform: translate(-8%); */
  }
}

/* End of new media query */
