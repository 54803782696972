.label {
  margin-bottom: 4px;
  margin-top: 10px;
  color: #333;
}
.input {
  padding: 12px;
  width: 92%;
  font-size: 0.9em;
  margin-bottom: 15px;
}
.green {
  color: rgb(68, 94, 68);
}
.red {
  color: #d76363;
}
p span {
  font-size: 18px;
  color: #d76363;
}

span.green,
span.red {
  font-size: 25px;
  display: block;
  margin-top: -10px;
}
.align {
  align-items: center;
  gap: 10px;
}
.whiteDisabledBg:disabled {
  background-color: #fff;
  border: 1px solid #999;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .label {
    font-size: 0.9rem;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .label {
    font-size: 0.95rem;
  }
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
