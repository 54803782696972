.row {
  display: flex;
}
.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.card {
  padding: 4rem;
  min-height: 20rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
