.root {
  padding: 20px;
  margin: 20px 10px 20px 0;
  line-height: 1.5em;
  font-size: 1em;
}
.info {
  background-color: #cff4fc;
  color: #21444c;
  border-left: 4px solid #82b4bf;
}
.warning {
  background-color: #fff3cd;
  color: #564406;
  border-left: 4px solid #b9a668;
}

/* New media query */
/* Mobile view */
@media only screen and (min-width: 375px) and (max-width: 416px) {
  .root {
    margin: 20px 10px 20px 0;
  }
}

/* landscape mobile view */
@media only screen and (max-width: 812px) and (orientation: landscape) {
  .root {
    margin: 20px 20px 20px 0;
  }
}
/* Media Queries */
@media only screen and (max-width: 767px) {
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 812px) {
}
@media only screen and (min-width: 813px) and (max-width: 1024px) {
}
/* End of new media query */
