.gap {
  gap: 20px;
}
.tab {
  padding: 5px 10px;
  display: inline-block;
  margin: 10px 0;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.pending {
  background-color: #fef2e3;
  color: #cc7208;
}
.cancelled {
  background-color: #fee4e3;
  color: #cc3608;
}
.settled {
  background-color: #ecf6e2;
  color: #369103;
}

ul.activity {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margins */
  height: 120px;
  overflow-y: auto;
  margin-top: 10px;
}

ul.activity li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 30px;
}

ul.activity li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  transform: translateY(-50%);
  width: 10px; /* Bullet width */
  height: 10px; /* Bullet height */
  background-color: #ddd; /* Bullet color */
  border-radius: 50%; /* Make it round */
  box-sizing: border-box;
}

ul.activity li::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  left: 4px; /* Center the line with respect to the bullet */
  top: -10px;
  bottom: 0;
  height: 100%;
  border-left: 2px solid #ddd; /* Make it a solid line */
  transform: translateY(50%); /* Align with bottom of bullet */
}

/* Remove the line from the last item */
ul.activity li:last-child::after {
  content: none;
}

.activityTitle {
  font-size: 0.9em;
}
.activityDate {
  font-size: 0.8em;
  color: #777;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .mobileLayout {
    flex-direction: column;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
