.rootDisplay {
  position: relative;
}

.display {
  position: relative;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  margin-top: -5px;
}
.icon {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 15px 20px;
}
.input {
  width: 100%;
  padding: 15px;
  font-size: 0.9em;
  color: #000;
}

.listWrapper {
  padding: 10px;
  border: 1px solid #999;
  width: 92%;
  margin-top: -30px;
  position: absolute;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.list {
  max-height: 140px;
  overflow: auto;
  font-size: 0.9em;
}

ul li {
  list-style: none;
}

.list li {
  padding: 5px 5px 10px 5px;
  cursor: pointer;
}
.label {
  margin-top: 10px;
  color: #333;
}
.title {
  font-size: 0.8em;
  color: #aaa;
}
