.btn,
.btnSubmit,
.btnConfirm {
  width: 100px;
  padding: 10px 0;
  margin: 20px 0 40px 0;
  font-size: 0.8em;
  border-radius: 0;
  background-color: rgb(34, 36, 66);
}
.btnSubmit {
  background-color: green;
}
.btnConfirm {
  background-color: rgb(168, 73, 73);
}
.btn:hover,
.btnSubmit:hover,
.btnConfirm:hover {
  transform: scale(1.1);
  background-color: rgb(51, 53, 77);
}
.btnSubmit:hover {
  background-color: rgb(57, 98, 57);
}
.btnConfirm:hover {
  background-color: rgb(202, 102, 102);
}
.input {
  width: 300px;
}
.inputAmount {
  width: 100px;
  text-align: center;
}
.dropdown {
  margin-top: 30px;
  width: 330px;
}
.mt20 {
  margin-top: 20px;
}

.screenScrollable {
  height: 70vh;
  overflow: auto;
  position: relative;
  padding: 10px;
}

/* Screen 1 */

/* Screen 2 */
.noGap {
  justify-content: flex-start;
  align-items: center;
}
.gap20 {
  gap: 20px;
}
.align {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.p10 {
  padding-top: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.shrink,
.shrinkInput {
  flex: none;
  margin-right: 20px;
}
.shrinkInput {
  margin-top: 5px;
}
/* Screen 3 */

/* Screen 4 */

/* Screen 5 */
.bigFont {
  font-size: 1.2em;
  margin-top: 10px;
}

/* Screen 6 */
.mobileOnly {
  display: none;
}

/* Screen 7 */
.zIndex1000 {
  z-index: 1000;
}
.zIndex990 {
  z-index: 990;
}
.zIndex980 {
  z-index: 980;
}
.hr80 {
  width: 80%;
  height: 1px; /* Set the height to make it smaller */
  border: none; /* Remove the default border */
  background-color: #eee; /* Set the background color */
  margin: 10px 0; /* Adjust margin for spacing */
}
/* Screen 8 */
.card {
  width: 80%;
  margin-right: 20px;
}
.addMore {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.btnAddMore {
  font-size: 0.7em;
  padding: 6px;
  border: solid 1px #999;
  cursor: pointer;
}
.hide {
  display: none;
}
.fileRecords {
  margin-top: 10px;
  font-size: 0.85em;
  color: #444;
}
.fileHeader {
  font-size: 0.8em;
  font-weight: bold;
}
.fileNo {
  flex: 0.2;
}
.fileName {
  flex: 1.5;
  padding-right: 10px;
}
.fileAction {
  flex: 0.3;
}
.fileRecords .fileAction {
  font-size: 0.8em;
  color: rgb(168, 73, 73);
  cursor: pointer;
}
.fileRecords .fileAction:hover {
  color: rgb(121, 52, 52);
  transform: scale(1.1);
}

/* Screen 9 */
.small {
  font-size: 0.89em;
  color: #555;
}

/* Screen 10 */

/* General mobile */
@media only screen and (max-width: 767px) {
  .mobileOnly {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .input {
    width: 80%;
  }
  .dropdown {
    width: 90%;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
  .screenScrollable {
    height: 80vh;
    /* background-color: red; */
  }
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
  /* .mobileOnly {
    display: block;
    margin-bottom: 30px;
  }
  .screenScrollable {
    height: 60vh !important;
  } */
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* .mobileOnly {
    display: block;
    margin-bottom: 30px;
  } */
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
