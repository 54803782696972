ul.list {
  margin-top: 20px;
}
ul.list li {
  list-style: none;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid #3dc39b;
  padding-bottom: 15px;
}
ul.list li:last-child {
  border-bottom: none;
}

ul.list li:hover {
  transform: scale(0.98);
}
.bg {
  background-color: aquamarine;
}
.modal {
  top: 10%;
  left: 10%;
}
.root {
  margin-top: 20px;
  width: 100%;
}
.root iframe {
  border: none;
  width: 60vw;
  height: 70vh;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .modal {
    top: 12%;
    left: 5%;
  }
  .root iframe {
    width: 100%;
    height: 250px;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
  /* .modal {
    top: 2%;
    left: 5%;
  }
  .root iframe {
    height: 70vh;
  } */
  .modal {
    top: 12%;
    left: 5%;
  }
  .root iframe {
    width: 80vw;
    height: 300px;
  }
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal {
    top: 10%;
    left: 5%;
  }
  .root iframe {
    width: 80vw;
    height: 350px;
  }
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal {
    top: 12%;
    left: 6%;
  }
  .root iframe {
    border: none;
    width: 70vw;
    height: 60vh;
  }
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
