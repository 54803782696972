.modal {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  padding: 30px;
  position: fixed;
  top: 20%;
  left: 30%;
  z-index: 1000;
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
  .modal {
    top: 30%;
    left: 10%;
    padding: 20px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

/* Larger Screens */
@media only screen and (min-width: 1025px) {
  /* Styles for tablet landscape and above */
}

/* Desktop */
@media only screen and (min-width: 1281px) {
  /* Styles for desktop */
}

/* Large Screens (e.g., large desktops or TVs) */
@media only screen and (min-width: 1921px) {
  /* Styles for large screens */
}
