.side,
.mtop {
  background-color: var(--secondary-color);
  /* background-image: url(../../../assets/image/png/loginImg2.png); */
  background-image: url(../../../assets/image/webp/bg.webp);
  background-repeat: no-repeat;
  background-position: 60% -50%;
  color: rgba(188, 201, 250, 0.59);
}

.side {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 0.8em;
}

.message {
  padding-top: 5px;
  color: #fff;
}

.mtop {
  display: none;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .side {
    display: none;
  }
  .mtop {
    display: block;
    padding: 5px 5px 0 5px;
    font-size: 0.7em;
    background-size: 100px;
  }
  .mtop p {
    padding-top: 5px;
    margin-bottom: -20px;
    font-size: 0.65rem;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
  .mtop {
    background-position: 95% -20%;
  }
  .mtop p {
    padding-left: 1rem;
    font-size: 0.65rem;
  }
  .mtop h2 {
    font-size: 1.3rem;
  }
  .message {
    padding: 1rem;
  }
}

/* Standard Mobile View */
@media only screen and (min-width: 479px) and (max-width: 767px) {
  .mtop {
    background-position: 90% -20%;
  }
  .mtop p {
    padding-left: 3rem !important;
    font-size: 0.7rem;
  }
  .mtop h2 {
    font-size: 1.4rem;
  }
  .message {
    padding: 1rem 3rem !important;
  }
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
  .message {
    padding: 1rem 9rem;
  }
  .mtop p {
    padding-left: 9rem;
  }
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side {
    flex: 0.7 !important;
    padding: 20px;
    background-position: 25% 30vh;
  }
  .message {
    padding: 5px 0;
  }
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side {
    background-position: 150% -60%;
  }
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
