.login,
.signUp {
  padding: 8rem 10rem;
  position: relative;
  /* height: 100vh; */
  margin: 10px;
  overflow: auto;
}

.signUp {
  padding-top: 2rem;
  padding-bottom: 0;
  height: 88vh;
}

.subheading {
  font-size: 0.95em;
  margin-top: 0px;
}

.subheading span {
  color: #f58109;
}

.form {
  padding-top: 20px;
  width: 350px;
}

.formSignup {
  padding-top: 10px;
  /* padding-right: 40px; */
  /* height: 70vh; */
  width: 380px;
  overflow: auto;
  margin-top: 20px;
}

.center,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.center {
  padding: 20px 0;
}
.link {
  cursor: pointer;
  font-size: 1em;
  color: var(--secondary-color);
}

.right {
  justify-content: flex-end;
  margin-top: -2px;
  margin-bottom: 30px;
}

.link:hover {
  /* color: var(--secondary-color200); */
  color: #f58109;
}

.accountType {
  height: 300px;
}

.modalTitle {
  text-align: center;
  margin-bottom: 1.5em;
}

.accountTypeBtn {
  padding: 30px 20px;
  margin: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border: 1px solid #999;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.accountTypeBtn:hover {
  background-color: var(--secondary-color500);
  color: white;
  transform: scale(1.1);
}

.back {
  display: flex;
  gap: 5px;
  cursor: pointer;
  font-size: 0.7rem;
  align-items: center;
  margin-bottom: 10px;
  color: #777;
}
.modal {
  left: 30%;
  top: 10%;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .subheading {
    margin-bottom: 20px;
  }

  .modalTitle {
    text-align: center;
    margin-bottom: 0em;
  }

  .accountTypeBtn {
    padding: 20px;
    margin: 5px;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
  .modal {
    left: 5%;
    top: 10%;
  }
  .accountTypeBtn {
    padding: 10px;
    margin: 5px;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
  }
  .login,
  .signUp {
    padding: 1rem !important;
    height: 100vh;
  }
  .signUp {
    height: 80vh;
  }
  .login h1,
  .signUp h1 {
    font-size: 1.5rem;
  }
  .subheading,
  .subheading span,
  .link {
    font-size: 0.8rem;
  }
  .form,
  .formSignup {
    padding: 0;
    width: 100% !important;
  }
  .center,
  .link {
    font-size: 0.9rem;
  }
}

/* Standard Mobile View */
@media only screen and (min-width: 479px) and (max-width: 767px) {
  .login,
  .signUp {
    padding: 2rem 3rem !important;
    height: 100vh;
  }
  .signUp {
    height: 80vh;
  }
  .login h1,
  .signUp h1 {
    font-size: 2rem;
  }
  .subheading,
  .subheading span,
  .link {
    font-size: 0.9rem;
  }
  .form,
  .formSignup {
    padding: 0;
    width: 100% !important;
  }
  .center,
  .link {
    font-size: 0.9rem;
  }
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
  .login,
  .signUp {
    padding: 2rem 3rem !important;
    height: 60vh !important;
  }

  .link,
  .right {
    font-size: 0.95rem;
  }
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login {
    padding: 8rem 4rem !important;
    flex: 1.5;
  }
  .signUp {
    padding: 3rem 5rem 0 5rem;
    height: 90vh !important;
  }
  .login h1 {
    font-size: 1.9rem;
  }
  .signUp h1 {
    font-size: 1.7rem;
  }
  .subheading,
  .subheading span {
    font-size: 0.95em;
  }

  .form,
  .formSignup {
    width: 100%;
  }
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login {
    padding: 7rem 5rem 0 5rem;
  }
  .signUp {
    padding: 3rem 5rem 0 5rem;
    height: 85vh;
  }
  .login h1,
  .signUp h1 {
    font-size: 1.9rem !important;
  }

  .subheading {
    font-size: 0.9em;
  }

  .form,
  .formSignup {
    width: 100%;
  }
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
