.component {
  padding: 40px 80px;
}
.alignRight {
  align-items: flex-end;
}

/* General mobile */
@media only screen and (max-width: 767px) {
  .alignRight {
    display: none;
  }
  .component {
    padding: 10px 20px;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
}

/* Tablet Portrait View */
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .component {
    padding: 20px !important;
    /* background-color: pink; */
  }
  .alignRight {
    padding: 20px;
  }
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
