.rootDisplay {
  position: relative;
  padding: 10px 0;
  display: flex;
  gap: 40px;
}

.listWrapper {
  padding: 10px;
  border: 1px solid #999;
  width: 55px;
}

.display {
  display: flex;
  position: relative;
  cursor: pointer;
  width: 40px;
  font-size: 0.8em;
}
.icon {
  position: relative;
  right: 20px;
  top: 18px;
}
.input {
  width: 40px;
  padding: 10px;
  font-size: 0.9em;
}

.listWrapper {
  margin-top: -10px;
  position: absolute;
  /* left: 0; */
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.list {
  max-height: 140px;
  overflow: auto;
}
.list li {
  cursor: pointer;
}

ul li {
  list-style: none;
}

.list li {
  padding: 0 5px 5px 5px;
}
.label {
  margin-bottom: 4px;
  margin-top: 10px;
  color: #333;
}
.title {
  font-size: 0.8em;
  color: #aaa;
}
