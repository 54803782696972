ul.menu {
  list-style: none;
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

ul.menu li {
  padding: 15px 0;

  cursor: pointer;
  color: #adadad;
  font-size: 1em;
}
ul.menu li:first-child {
  padding-left: 20px;
}

ul.menu li.active,
ul.menu li:hover {
  /* font-weight: bold; */
  color: #061641;
}
.action {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.action a {
  padding: 10px;
  border-radius: 100px;
  /* font-size: 0.8rem; */
  /* letter-spacing: 0.1em; */
  display: flex;
  align-items: center;
  /* gap: 5px; */
  color: #fff;
  background-color: var(--secondary-color);
  text-decoration: none;
}
.action a:hover {
  transform: scale(1.05);
}

.scrollable {
  height: 50vh;
  padding: 0 10px;
  overflow: auto;
}
