.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  padding: 30px;
  margin: 20px 2px;
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
  .card {
    padding: 20px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

/* Larger Screens */
@media only screen and (min-width: 1025px) {
  /* Styles for tablet landscape and above */
}

/* Desktop */
@media only screen and (min-width: 1281px) {
  /* Styles for desktop */
}

/* Large Screens (e.g., large desktops or TVs) */
@media only screen and (min-width: 1921px) {
  /* Styles for large screens */
}
