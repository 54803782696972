.button {
  padding: 15px 30px;
  background-color: var(--secondary-color500);
  color: #fff;
  border: none;
  width: 100%;
  font-family: "Merriweather Sans";
  font-size: 0.95rem;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.button:hover {
  transform: scale(0.98);
  background-color: var(--secondary-color100);
}

.buttonClose {
  padding: 5px 8px 4px 8px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  transition: transform 0.5s ease;
}
.buttonClose:hover {
  transform: scale(0.9);
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .button {
    font-size: 0.9rem;
    padding: 15px 20px;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
