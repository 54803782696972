.header {
  padding: 25px 80px;
  background-color: var(--secondary-color);
  color: #fff;
  justify-content: space-between;
}
.title a {
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
}
.actions {
  gap: 25px;
}

/* General mobile */

@media only screen and (max-width: 767px) {
  .header {
    padding: 30px;
  }
  .title a {
    font-size: 1rem;
    letter-spacing: 0.03em;
  }
}

/* Smaller Mobile View (e.g., iPhone SE) */
@media only screen and (max-width: 479px) {
}

/* Standard Mobile View */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Landscape View */
@media only screen and (max-width: 991px) and (orientation: landscape) {
  .header {
    padding: 30px;
  }
}

/* Tablet Portrait View */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Tablet Landscape View */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Desktop View */
@media only screen and (min-width: 1200px) {
}
